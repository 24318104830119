$theme-font-family: Lato, sans-serif;

$fs-80: 80px;
$fs-64: 64px;
$fs-32: 32px;
$fs-20: 20px;
$fs-23: 23px;
$fs-24: 24px;
$fs-18: 18px;
$fs-16: 16px;
$fs-14: 14px;
$fs-15: 15px;
$fs-13: 13px;
$fs-12: 12px;
$fs-9: 9px;
$fs-8: 8px;
$fs-10: 10px;
$fs-11: 11px;
$fs-29: 29px;
$fs-40: 40px;
$fs-48: 48px;
$fs-46: 46px;
$fs-36: 36px;
$fw-800: 800;
$fw-700: 700;
$fw-600: 600;
$fw-500: 500;
$fw-400: 400;

$lh-28: 28px;

$btn-border-radius: 25px;
$width: auto;

$theme-color: #2b60af;
$back-arrow-color: black;
$invisible-color: transparent;
$emotion-color: #b76a24;
$notification-color: #ea1f1f;
$error-color: #f44336;
$non-veg-color: #92271a;
$success-color: #49bd46;
$frequent-color: white;
$scream-icon-color: #666869;
$landing-bg-color: #f8fbfe;
$orange-color: #fc8631;
$imagepreview-outline-color: #e8e8e9;
$font-color: #1b1d1f;
$grey-disable-color: #969798;
$red-color: #ff1414;
$grey-color: #888686;
$hover-color: #f5f5f5;
$unread-notification-bg-color: #f3f6fc;
$unread-notification-bg-color-hover: #e9edf4;
$read-notification-bg-color-hover: #f0ecec;
$profile-color: #e0e0e0;
$step-icon-color: #eaeff7;
$succes-icon-color: #1a9226;
$box-shadow-color: #00000026;
$signUp-line-color: #b8b9ba;
$fields-default-color: #0000006b;
$fields-focus-color: #3f51b5;
$text-red-color: #ce3725;
$section-background-color: #faf5e9;
$border-left: #919192;
$green: #22bb31;
$chinese-siver: #ccc;
$events-background-color: #00000080;
$event-pdf-format-background-color: #ffffff;
$black-dark-shade: #323232;
$events-box-shade: #0000000f;
$section-background-color: #faf5e9;
$dark-gray: #49454f;
$chinese-siver: #ccc;
$wine: #92271a;
$forest-green: #1a9226;
$light-grey-color: #979797;
$gold-rated: #e8b523;
$service-border-color: #64b5f6;
$service-back-ground-color: #e3f2fd;
$service-icon-color: #1c1b1f;
$default-text-color: #5f6368;
$powered-by-color: #666668;
$service-title-color: #292d32;
$check-gold: #ce9f25;
