@import "./variables";
.fs-64 {
  font-size: $fs-64 !important;
}
.fs-48 {
  font-size: $fs-48 !important;
}
.fs-46 {
  font-size: $fs-46 !important;
}
.fs-36 {
  font-size: $fs-36 !important;
}
.fs-32 {
  font-size: $fs-32 !important;
}
.fs-24 {
  font-size: $fs-24 !important;
}
.fs-23 {
  font-size: $fs-23 !important;
}
.fs-20 {
  font-size: $fs-20 !important;
}
.fs-15 {
  font-size: $fs-15 !important;
}

.fs-18 {
  font-size: $fs-18 !important;
}
.fs-16 {
  font-size: $fs-16 !important;
}
.fs-14 {
  font-size: $fs-14 !important;
}
.fs-12 {
  font-size: $fs-12 !important;
}
.fs-13 {
  font-size: $fs-13 !important;
}
.fs-9 {
  font-size: $fs-9 !important;
}
.fs-8 {
  font-size: $fs-8 !important;
}
.fs-10 {
  font-size: $fs-10 !important;
}
.fs-11 {
  font-size: $fs-11 !important;
}
.fs-29 {
  font-size: $fs-29 !important;
}
.fs-80 {
  font-size: $fs-80 !important;
}
.fs-40 {
  font-size: $fs-40 !important;
}
